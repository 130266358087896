/**
 * Copyright (C) 2021 - 2025 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export enum ProfileStatus {
	ACTIVE = 'ACTIVE',
	QUARANTINED = 'QUARANTINED',
	BLOCKED = 'BLOCKED',
}

export const profileStatusData = {
	[ProfileStatus.ACTIVE]: {
		id: ProfileStatus.ACTIVE,
		label: 'Active',
	},
	[ProfileStatus.QUARANTINED]: {
		id: ProfileStatus.QUARANTINED,
		label: 'Quarantined',
	},
	[ProfileStatus.BLOCKED]: {
		id: ProfileStatus.BLOCKED,
		label: 'Blocked',
	},
};
