/**
 * Copyright (C) 2021 - 2025 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule({ imports: [], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class IconRegistryModule {
	private readonly topLevelIcons: Array<string> = [
		'ic-add-new-item',
		'ic-ai-translation',
		'ic-aicon',
		'ic-air-speed',
		'ic-airfryer',
		'ic-all-in-one-iron',
		'ic-approve',
		'ic-blender',
		'ic-calendar-2',
		'ic-check-circle',
		'ic-clear-small',
		'ic-close-dark-tag',
		'ic-comment-level',
		'ic-copy-primary',
		'ic-delete-red',
		'ic-duration',
		'ic-edit-green',
		'ic-error-circle-small',
		'ic-espresso-machine',
		'ic-expand-more',
		'ic-export-file-green',
		'ic-export-universal',
		'ic-file-dark-green',
		'ic-filter',
		'ic-garment-steamer',
		'ic-globe',
		'ic-heart',
		'ic-help',
		'ic-humidity',
		'ic-info-orange',
		'ic-info-secondary',
		'ic-kitchen-machine',
		'ic-link-green',
		'ic-no-device',
		'ic-nutrimax',
		'ic-nutrition-missing',
		'ic-nutrition-ok',
		'ic-move-to',
		'ic-move-to-live-green',
		'ic-move-to-live-grey',
		'ic-pasta-maker',
		'ic-pin',
		'ic-positive-fill',
		'ic-pressure',
		'ic-reaction-like-filled',
		'ic-reaction-like',
		'ic-reply-level',
		'ic-reported-content',
		'ic-reported-content',
		'ic-reported-content',
		'ic-rice-cooker',
		'ic-round-warning',
		'ic-search',
		'ic-share',
		'ic-speed',
		'ic-save',
		'ic-steam-generator-iron',
		'ic-steam-iron',
		'ic-temperature',
		'ic-three-dots-horizontal',
		'ic-unit-conversion-missing',
		'ic-unit-conversion-ok',
		'ic-upload',
		'ic-warning',
		'ic-warning-error',
		'ic-warning-info',
		'ic-warning-info-small',
		'ic-warning-orange',
	];

	private readonly tableIcons: Array<string> = [
		'ic-articles',
		'ic-collections',
		'ic-comments',
		'ic-countries',
		'ic-devices',
		'ic-favorite',
		'ic-nutrition-info',
		'ic-recipes',
		'ic-reports',
		'ic-translations',
		'ic-user-type',
		'ic-views',
	];

	constructor(
		private readonly matIconRegistry: MatIconRegistry,
		private readonly domSanitizer: DomSanitizer,
	) {
		this.registerCustomIcons();
	}

	private registerCustomIcons(): void {
		this.registerTopLevelIcons(this.topLevelIcons);
		this.registerTableIcons(this.tableIcons);
	}

	private registerTopLevelIcons(iconNames: Array<string>): void {
		this.registerIcons(iconNames);
	}

	private registerTableIcons(iconNames: Array<string>): void {
		this.registerIcons(iconNames, 'table/');
	}

	private registerIcons(iconNames: Array<string>, folder: string = ''): void {
		iconNames.forEach((iconName: string) => {
			this.matIconRegistry.addSvgIcon(
				iconName,
				this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/img/${folder}${iconName}.svg`),
			);
		});
	}
}
