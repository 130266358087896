/**
 * Copyright (C) 2021 - 2025 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, HasOne, ModelConfig, ModelEndpoints } from 'ngx-hal';
import { ScreenName } from '../enums/screen-name.enum';
import { HalDatastoreModel } from '../services/datastore/models/hal-datastore-model.model';
import { unescapeHtml } from '../utils/helpers/helpers';
import { Administrator } from './administrator.model';

@ModelConfig({
	type: 'SearchFilter',
})
export class SearchFilter extends HalDatastoreModel {
	@Attribute()
	public name: string;

	@Attribute()
	public screenName: ScreenName;

	@Attribute()
	public value: string;

	public get parsedValue(): Record<string, string | Array<string>> {
		return JSON.parse(unescapeHtml(this.value));
	}

	public outdated: boolean;

	@HasOne({
		propertyClass: 'Administrator',
	})
	public administrator: Administrator;

	public get modelEndpoints(): ModelEndpoints {
		return {
			collectionEndpoint: this.administrator.getRelationshipUrl('searchFilters'),
		};
	}
}
