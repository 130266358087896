/**
 * Copyright (C) 2021 - 2025 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export enum Feature {
	ADMIN_UI_USERS = 'ADMIN_UI_USERS',
	ANNOUNCEMENTS = 'ANNOUNCEMENTS',
	ARTICLES = 'ARTICLES',
	CAMPAIGN = 'CAMPAIGN',
	COMMENTS = 'COMMENTS',
	CONSUMERS = 'CONSUMERS',
	CONTENT_PUBLISHING = 'CONTENT_PUBLISHING',
	CONTENT_SCHEDULER = 'CONTENT_SCHEDULER',
	COUNTRIES = 'COUNTRIES',
	CUSTOM_TABLES = 'CUSTOM_TABLES',
	DAM = 'DAM',
	EASY_RECIPE_BOOK = 'EASY_RECIPE_BOOK',
	HIGHLIGHTS = 'HIGHLIGHTS',
	INGREDIENTS = 'INGREDIENTS',
	ONBOARDING_ANNOUNCEMENT = 'ONBOARDING_ANNOUNCEMENT',
	PERSONAL_MESSAGING_SYSTEM = 'PERSONAL_MESSAGING_SYSTEM',
	PRODUCT_RECIPE_LISTS = 'PRODUCT_RECIPE_LISTS',
	PRODUCTS = 'PRODUCTS',
	PROMO_CODES = 'PROMO_CODES',
	PUSH_NOTIFICATIONS = 'PUSH_NOTIFICATIONS',
	RECIPE_BOOK_CAROUSELS = 'RECIPE_BOOK_CAROUSELS',
	RECIPE_BOOKS = 'RECIPE_BOOKS',
	RECIPE_CAROUSELS = 'RECIPE_CAROUSELS',
	RECIPES = 'RECIPES',
	REPORTED_CONTENT = 'REPORTED_CONTENT',
	SHOP_ANNOUNCEMENTS = 'SHOP_ANNOUNCEMENTS',
	TIMER = 'TIMER',
	TRANSLATIONS_AND_CONTENT_ACTIONS = 'TRANSLATIONS_AND_CONTENT_ACTIONS',
}
