/**
 * Copyright (C) 2021 - 2025 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

export enum ReportedContentStatus {
	REPORTED = 'REPORTED',
	REVIEWED = 'REVIEWED',
}

export const reportedContentStatusData = {
	[ReportedContentStatus.REPORTED]: {
		id: ReportedContentStatus.REPORTED,
		translationKey: 'reportedContentStatus.reported',
	},
	[ReportedContentStatus.REVIEWED]: {
		id: ReportedContentStatus.REVIEWED,
		translationKey: 'reportedContentStatus.reviewed',
	},
};
