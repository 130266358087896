/**
 * Copyright (C) 2021 - 2025 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Attribute, HasOne, ModelConfig, ModelEndpoints } from 'ngx-hal';
import { HalDatastoreModel } from '../services/datastore/models/hal-datastore-model.model';
import { Administrator } from './administrator.model';
import { Content } from './content.model';

@ModelConfig({
	type: 'Lock',
})
export class ContentLock extends HalDatastoreModel {
	public static modelType = 'ContentLock';

	@Attribute()
	public lockedAt: Date;

	@Attribute()
	public lockedUntil: Date;

	@HasOne({ propertyClass: Administrator, externalName: 'administrator' })
	public lockedBy: Administrator;

	@HasOne({ propertyClass: null, includeInPayload: true })
	public content: Content;

	public get modelEndpoints(): ModelEndpoints {
		return {
			collectionEndpoint: this.content.getRelationshipUrl('lockEndpoint'),
		};
	}
}
